html,
body,
#root {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  width: 100vw;
  height: 100vh;
}

body {
  background: white;
  font-family: "Inter var", sans-serif;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(
    to right,
    #ffffff 25%,
    #fbfbfb 50.00%,
    #111 50.00%,
    #000000 75%
  ); */
  /* background: #00ff00; */
}

h1 {
  position: absolute;
  top: 43px;
  left: 140px;
  padding: 0;
  margin: 40px;
  font-size: 5em;
  line-height: 0.7em;
  letter-spacing: -6px;
  color: #272730;
}

.left-hitbox {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
}

.left-mobile-hitbox {
  position: absolute;
  width: 50vw;
  height: 100vh;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: 9999;
}
.right-mobile-hitbox {
  position: absolute;
  width: 50vw;
  height: 100vh;
  background-color: transparent;
  left: 50vw;
  top: 0;
  z-index: 9999;
}

#root {
  position: relative;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: white;
}

body {
  background: #272727;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Helvetica Neue", Helvetica, Arial, Roboto, Ubuntu, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.full {
  width: 100%;
  height: 100%;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 3px;
  background: #272727;
}

.loading-bar {
  height: 3px;
  background: white;
}

.loading-data {
  display: inline-block;
  position: relative;
  font-variant-numeric: tabular-nums;
  margin-top: 0.8em;
  color: #f0f0f0;
  font-size: 0.6em;
}
